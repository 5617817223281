import React from 'react';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export const SEO = ({ title, description, pathname, children }) => {
  const { siteUrl: origin } = useSiteMetadata();

  const seo = {
    title,
    description,
    url: `${origin}${pathname || ``}`,
    urlEn: `${origin}/en${pathname || ``}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta
        name="google-site-verification"
        content="HbM3WJhSDjW4E7qku0soB8jCvl5VeDzjlPrJy2aDh30"
      />
      <link rel="alternate" hrefLang="pl" href={seo.url} />
      <link rel="alternate" hrefLang="en" href={seo.urlEn} />

      <link rel="canonical" href={seo.url} />

      <meta name="viewport" content="width=device-width,initial-scale=1" />

      {children}
    </>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  children: PropTypes.node,
};
