/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import * as S from '../components/Privacy/Privacy.styles';
import SectionWrapper from '../components/Common/SectionWrapper';
import { MainLayout } from '../components/MainLayout';
import waveIcon from '../assets/icons/wave-small-orange.svg';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import { seoData } from '../static/seoData';

const Prywatnosc = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <SectionWrapper>
        <S.Wrapper>
          <S.WaveIcon src={waveIcon} alt="wave " />
          <S.Title>{t('privacy_title')}</S.Title>
          <S.Paragraph>
            Niniejszy dokument dotyczy internetowej www.optymisja.pl. Opisuje on
            zasady postępowania z danymi osobowymi oraz wykorzystywania plików
            cookies.
          </S.Paragraph>
          <S.Point>{`1. ${t('privacy_definitions_header')}`}</S.Point>
          <S.Paragraph>
            Administrator: Maria Kmita, prowadząca działalność gospodarczą pod
            nazwą Maria Kmita Optymisja z siedzibą pod adresem: ul. Bartosza
            Głowackiego 2/1, 51-691 Wrocław, wpisana do Centralnej Ewidencji i
            Informacji o Działalności Gospodarczej, posługująca się numerem NIP
            8982283221
          </S.Paragraph>
          <S.Paragraph>
            Strona: strona internetowa dostępna pod adresem https://optymisja.pl
          </S.Paragraph>
          <S.Paragraph>
            Użytkownik: każdy podmiot korzystający ze Strony.
          </S.Paragraph>

          <S.Point>{`2. ${t('privacy_personal_data_header')}`}</S.Point>
          <S.Paragraph>
            Gromadzimy dane niezbędne do prawidłowego i pełnego funkcjonowania
            Strony.
          </S.Paragraph>
          <S.Paragraph>
            Dane osobowe gromadzone są w celu obsługi zapytań wysyłanych przez
            formularz kontaktowy. Należą do nich: imię, nazwisko, e-mail, numer
            telefonu Użytkownika.
          </S.Paragraph>
          <S.Paragraph>
            Administratorem danych osobowych Użytkownika jest Administrator.
          </S.Paragraph>
          <S.Paragraph>
            Dane osobowe nie są przekazywane podmiotom trzecim.
          </S.Paragraph>
          <S.Paragraph>
            Podanie danych jest dobrowolne, ale konieczne do celów wymienionych
            wyżej
          </S.Paragraph>
          <S.Paragraph>
            Użytkownikowi przysługują następujące prawa:
            <li>
              prawo do dostępu, sprostowania, usunięcia, ograniczenia
              przetwarzania danych osobowych przekazanych przez Użytkownika
            </li>
            <li>
              prawo do cofnięcia zgody na przetwarzanie danych osobowych w
              określonym celu, jeżeli Użytkownik uprzednio taką zgodę wyraził,
            </li>
            <li>prawo do przenoszenia danych</li>
            <li>
              prawo do wniesienia skargi do organu nadzorczego w związku z
              przetwarzaniem danych osobowych przez Administratora
            </li>
          </S.Paragraph>

          <S.Point>{`3. ${t('privacy_cookies_header')}`}</S.Point>
          <S.Paragraph>Strona korzysta z plików cookies.</S.Paragraph>
          <S.Paragraph>
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
            szczególności pliki tekstowe, które przechowywane są w urządzeniu
            końcowym Użytkownika Strony i przeznaczone są do korzystania ze
            Strony. Pliki ookies zazwyczaj zawierają nazwę strony internetowej,
            z której pochodzą, czas przechowywania ich na urządzeniu końcowym
            oraz unikalny numer.
          </S.Paragraph>
          <S.Paragraph>
            Administrator przechowuje pliki cookies na urządzeniu końcowym
            Użytkownika, a następnie uzyskuje dostęp do informacji w nich
            zawartych w celach statystycznych.
          </S.Paragraph>
          <S.Paragraph>
            W celu zbierania danych statystycznych korzystamy z usług Google
            Analytics, które gromadzą informacje na temat Twoich odwiedzin
            strony. W tym celu wykorzystywane są pliki cookies firmy Google LLC
            dotyczące usługi Google Analytics.
          </S.Paragraph>
          <S.Paragraph>
            Administrator informuje, że przeglądarki internetowe dopuszczają
            możliwość zablokowania przechowywania plików cookies. Przeglądarki
            internetowe umożliwiają również usunięcie zapisanych wcześniej w ich
            pamięci plików cookies. Szczegółowe informacje na ten temat zawiera
            pomoc lub dokumentacja przeglądarki internetowej.
          </S.Paragraph>
          <S.Paragraph>
            W przypadku braku zgody Użytkownika na przechowywanie lub
            zapisywanie plików cookies należy zmienić wyżej wspomniane
            ustawienia przeglądarki internetowej.
          </S.Paragraph>

          <S.Point>{`4. ${t('privacy_server_logs_header')}`}</S.Point>
          <S.Paragraph>
            Korzystanie ze strony wiąże się z przesyłaniem zapytań do serwera,
            na którym przechowywana jest strona. Każde zapytanie skierowane do
            serwera zapisywane jest w logach serwera.
          </S.Paragraph>
          <S.Paragraph>
            Logi obejmują m.in. adres IP, datę i czas serwera, informacje o
            przeglądarce internetowej i systemie operacyjnym z jakiego korzysta
            Użytkownik. Logi zapisywane i przechowywane są na serwerze.
          </S.Paragraph>
          <S.Paragraph>
            Dane zapisane w logach serwera nie są kojarzone z konkretnymi
            osobami korzystającymi ze strony i nie są wykorzystywane przez
            Administratora w celu identyfikacji Użytkownika.
          </S.Paragraph>
          <S.Paragraph>
            Logi serwera stanowią wyłącznie materiał pomocniczy służący do
            administrowania stroną, a ich zawartość nie jest ujawniana nikomu
            poza osobami upoważnionymi do administrowania serwerem.
          </S.Paragraph>
        </S.Wrapper>
      </SectionWrapper>
    </MainLayout>
  );
};

export default Prywatnosc;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.privacy[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.privacy}
    />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
