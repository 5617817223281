import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
  position: relative;
`;
export const Title = styled.h1`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  line-height: 5.4rem;
  letter-spacing: 0.03em;
`;
export const Point = styled.h3`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraphBig};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0;
  line-height: 2.8rem;
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 1.6rem 0 3.2rem;
  line-height: 2.8rem;
`;

export const WaveIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 0;
  }
`;
